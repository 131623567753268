import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routers from "./Routers";
import { setLanguage } from "./store/language";

function App() {
  const browserLanguage = window.navigator.language.split("-")[0];
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage(browserLanguage));
  }, [browserLanguage, dispatch]);

  return (
    <div>
      <Routers language={language} />
    </div>
  );
}

export default App;

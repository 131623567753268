const en = {
  whoWeAre: "WHO WE ARE",
  aboutDesc:
    "LenoWorks is a research and development company focused on the design and development of educational contents and applications. Lenoworks offers effective, efficient, sustainable and easily accessible, fun digital education solutions for all age levels. It also develops low-cost tools for trainer training and in-house training. Subjects and concepts that are more costly and from time to time impossible to describe by traditional methods, are transferred to the users by a much faster, easier and effective method with the aid of two and three dimensional animations. Considering the rapid development in the mobile field, Lenoworks develops educational applications in this field.",
  home: "Home",
  about: "About",
  services: "Services",
  contact: "Contact",

  mobileApp: "Mobile applications",
  augmentedReality: "Augmented Reality",
  contentDev: "Content Development",
  elaern: "Interactive e-learning contents",
  animations: "2D, 3D animations",
  questionBank: "Question banks",
  systemAdmin: "System administration",
  teacherTraining: "Teacher’s training",
  webInstructionDev: "Web based instruction development education",
  mobileAppDesc:
    "e-Learning mobile apps help distance learning courses from anywhere in the world.",
  augmentedRealityDesc:
    "Augmented reality (AR) is an interactive experience that combines the real world and computer-generated content.",
  contentDevDesc:
    "The process of creating, editing, manipulating and maintaining the contents in order to provide knowledgeable fillings to the users.",
  elaernDesc:
    "Interactive learning is a technique that get students actively engaged in the learning process, through the use of technology.",
  animationsDesc:
    "3D animation is a graphic technique that utilizes motion in order to bring characters, objects, props, and more to life.",
  questionBankDesc:
    "A question bank is a collection of questions that is stored for repeated use. It can be imported for use in assessments for the courses. ",
  systemAdminDesc:
    "System administration is the field of work in which someone manages one or more systems, be they software, hardware, servers or workstations",
  teacherTrainingDesc:
    "Teacher training refers to any support and capacity building which enables teachers and other education personnel to effectively instruct and assess learners on the curricula.",
  webInstructionDevDesc:
    "WBI is an innovative approach of instructional strategies implemented within a constructivist and collaborative learning environment, utilizing the resources of the web.",
  projects: "Projects",
};
export default en;

import { useRef } from "react";

import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import FeatureSection from "./FeatureSection";
import Hero from "./Hero";
import ServiceSection from "./ServiceSection";

function HomeOne({ language }) {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const handleClick = (refId) => {
    console.log("clikeee");
    if (refId === 1) ref.current?.scrollIntoView({ behavior: "smooth" });
    else if (refId === 2) ref1.current?.scrollIntoView({ behavior: "smooth" });
    else if (refId === 3) ref2.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeOne clicke={handleClick} language={language} />
      <Hero />
      <FeatureSection refe={ref} language={language} />
      <ServiceSection refe={ref1} language={language} />
      {/* <TeamSection teams={data} />

      <AboutSection />

      <WorkProcessSection />
      <PricingSection />
      <GallerySection />
      <NewsSection blogs={blogs} /> */}
      <FooterHomeOne refe={ref2} clicke={handleClick} language={language} />
      <BacktoTopCom />
    </div>
  );
}

export default HomeOne;

import { configureStore } from "@reduxjs/toolkit";
import language from "./language";
import rtlSetting from "./rtlSetting";

export default configureStore({
  reducer: {
    rtlSetting,
    language,
  },
});

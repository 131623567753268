import React from "react";

function AboutSection() {
  return (
    <section className="about-two pt-100 rpt-80 pb-195 rpb-100">
      <div className="container">
        <div className="about-wrap pt-20">
          <div className="row justify-content-center">
            <div className="row justify-content-center">
              <h1>DigiAddiction</h1>
            </div>
            <div className="col-lg-8 pt-20">
              <div className="rmb-70 wow delay-0-2s">
                <img
                  src={
                    require(`../../assets/images/diginewsletter0.jpg`).default
                  }
                  alt="About"
                />
                <img
                  src={
                    require(`../../assets/images/diginewsletter1.jpg`).default
                  }
                  alt="About"
                />
                <img
                  src={
                    require(`../../assets/images/diginewsletter2.jpg`).default
                  }
                  alt="About"
                />
                <img
                  src={
                    require(`../../assets/images/diginewsletter3.jpg`).default
                  }
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;

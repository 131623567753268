import logo from "../../../../assets/images/logos/logo-white.png";

function FooterHomeOne({ refe, clicke, language }) {
  return (
    <footer className="main-footer footer-one text-white">
      <div className="footer-widget-area bgs-cover pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="text"></div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Page Links</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">{language.home}</a>
                  </li>
                  <li>
                    <a onClick={() => clicke(1)}>{language.about}</a>
                  </li>
                  <li>
                    <a onClick={() => clicke(2)}>{language.services}</a>
                  </li>
                  <li>
                    <a onClick={() => clicke(3)}>{language.contact}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div ref={refe} className="col-lg-5 col-sm-6">
              <div className="footer-widget contact-widget mr-30 rmr-0">
                <h4 className="footer-title">{language.contact}</h4>
                <ul className="list-style-two">
                  <li>
                    <i className="fas fa-map-marker-alt"></i> Hammfelddamm 4a,
                    Düsseldorf - Neuss, 41460, Germany
                  </li>
                  <li>
                    <i className="fas fa-phone"></i>
                    <a href="tel:+4921318819282"> +49 2131 881 9282 </a>
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> Mon-Sat 8:00 - 16:00
                  </li>
                  {/* <li>
                    <i className="fas fa-phone-alt"></i>
                    <a href="callto:+905324963632">+90532 496 36 32</a>
                  </li> */}
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:info@lenoworks.com">info@lenoworks.com</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6">
              <div className="footer-widget newsletter-widget">
                <h4 className="footer-title">Newsletter</h4>
                <p>
                  Lorem ipsum dolor sit amet, <br />
                  consectetur adipisicing
                </p>
                <form action="#" method="post">
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Your Email Address"
                    required
                  />
                  <button type="submit">
                    <i className="fa fa-location-arrow"></i>
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-area bg-blue">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a href="http://facebook.com">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitter.com">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.pinterest.com/">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
            <p>copyright 2022 LenoWorks All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHomeOne;

import React, { useState } from "react";

function ServiceSection({ refe, language }) {
  const mobileAppDesc = language.mobileAppDesc
    ? language.mobileAppDesc.substring(0, 80) + "...".toString()
    : "";

  const augmentedRealityDesc = language.augmentedRealityDesc
    ? language.augmentedRealityDesc.substring(0, 80) + "...".toString()
    : "";

  const contentDevDesc = language.contentDevDesc
    ? language.contentDevDesc.substring(0, 76) + "...".toString()
    : "";

  const elaernDesc = language.elaernDesc
    ? language.elaernDesc.substring(0, 80) + "...".toString()
    : "";

  const animationsDesc = language.animationsDesc
    ? language.animationsDesc.substring(0, 80) + "...".toString()
    : "";
  const questionBankDesc = language.questionBankDesc
    ? language.questionBankDesc.substring(0, 80) + "...".toString()
    : "";

  const systemAdminDesc = language.systemAdminDesc
    ? language.systemAdminDesc.substring(0, 80) + "...".toString()
    : "";

  const teacherTrainingDesc = language.teacherTrainingDesc
    ? language.teacherTrainingDesc.substring(0, 80) + "...".toString()
    : "";

  const webInstructionDevDesc = language.webInstructionDevDesc
    ? language.webInstructionDevDesc.substring(0, 80) + "...".toString()
    : "";

  const appTitleAndDesc = [
    {
      title: "Mobile App    ",
      desc: "e-Learning mobile apps help distance learning courses from anywhere in the world. It makes it possible to participate in virtual classrooms, collaborate with students and teachers from around the globe. ",
    },
    {
      title: "Augmented reality (AR)",
      desc: "Augmented reality (AR) is an interactive experience that combines the real world and computer-generated content. AR can be defined as a system that incorporates three basic features: a combination of real and virtual worlds, real-time interaction, and accurate 3D registration of virtual and real objects.",
    },
    {
      title: "Content Development",
      desc: "The process of creating, editing, manipulating and maintaining the contents in order to provide knowledgeable fillings to the users. Contents developed aid a lot in establishing and growing the thinking and gaining overall information about the person, company, organization, nation and almost all the fields.",
    },
    {
      title: "Interactive e-learning content",
      desc: "Interactive learning is a technique that get students actively engaged in the learning process, through the use of technology. This is in contrast to more passive techniques like the traditional lecture.",
    },
    {
      title: "3D 2D Animations",
      desc: "3D animation is a graphic technique that utilizes motion in order to bring characters, objects, props, and more to life.  Although 3D animation has been used in the creation of video games, films, and TV shows, its usages have also grown in e-learning. 2D animation is a useful tool for e-learning, and it's not just for kids. With animation, educators can take their content to the next level, make it engaging, easy to understand, and emotionally accessible to all kinds of viewers.",
    },
    {
      title: "Question bank",
      desc: "A question bank is a collection of questions that is stored for repeated use. It can be imported for use in assessments for the courses. Instructors typically use banks to create a database of questions they can reuse in multiple assessments.",
    },
    {
      title: "System administration",
      desc: "System administration is the field of work in which someone manages one or more systems, be they software, hardware, servers or workstations. Its goal is ensuring the systems are running efficiently and effectively.",
    },
    {
      title: "Teacher’s training",
      desc: "Teacher training refers to any support and capacity building which enables teachers and other education personnel to effectively instruct and assess learners on the curricula. Teacher development programs are based on actual needs of both teachers and learners.",
    },
    {
      title: "Web based",
      desc: "WBI is an innovative approach of instructional strategies implemented within a constructivist and collaborative learning environment, utilizing the resources of the web. ",
    },
  ];

  const [modalText, setModalText] = useState("");
  const [modalTittleText, setModalTittleText] = useState("");
  const [show, setShow] = useState(false);
  const OpenPopUp = (index) => {
    setShow(true);
    const currentText = appTitleAndDesc[index];
    setModalText(currentText.desc);
    setModalTittleText(currentText.title);
  };

  const ClosePopUp = () => {
    setShow(false);
    setModalText("");
    setModalTittleText("");
  };
  return (
    <section ref={refe} className="services-section pt-40 rpt-40 pb-90 rpb-70">
      <div className="container">
        <div className={`myoverlay ${show ? "d-flex" : ""}`}>
          <div className="mymodal d-block " tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{modalTittleText}</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => ClosePopUp()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{modalText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-2s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-application"></i>
                </div>
                <h6>{language.mobileApp}</h6>
                <p>{mobileAppDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.mobileApp}</p>
                <a onClick={() => OpenPopUp(0)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-server"></i>
                </div>
                <h6>{language.augmentedReality}</h6>
                <p>{augmentedRealityDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.augmentedReality}</p>
                <a onClick={() => OpenPopUp(1)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web"></i>
                </div>
                <h6>{language.contentDev}</h6>
                <p>{contentDevDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.contentDev}</p>
                <a onClick={() => OpenPopUp(2)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-maintenance"></i>
                </div>
                <h6>{language.elaern}</h6>
                <p>{elaernDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.elaern}</p>
                <a onClick={() => OpenPopUp(3)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-presentation"></i>
                </div>
                <h6>{language.animations}</h6>
                <p>{animationsDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.animations}</p>
                <a onClick={() => OpenPopUp(4)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-information"></i>
                </div>
                <h6>{language.questionBank}</h6>
                <p>{questionBankDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.questionBank}</p>
                <a onClick={() => OpenPopUp(5)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-admin"></i>
                </div>
                <h6>{language.systemAdmin}</h6>
                <p>{systemAdminDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.systemAdmin}</p>
                <a onClick={() => OpenPopUp(6)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-data"></i>
                </div>
                <h6>{language.teacherTraining}</h6>
                <p>{teacherTrainingDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.teacherTraining}</p>
                <a onClick={() => OpenPopUp(7)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web-programming"></i>
                </div>
                <h6>{language.webInstructionDev}</h6>
                <p>{webInstructionDevDesc}</p>
                <a className="btn-circle" href="#">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Lenoworks Solutions</h3>
                <p>{language.webInstructionDev}</p>
                <a onClick={() => OpenPopUp(8)} className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;

import React, { useRef } from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import AboutSection from "./AboutSection";

function HomeTwo({ language }) {
  const ref2 = useRef(null);
  const handleClick = (refId) => {
    if (refId === 3) ref2.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <HeaderHomeOne language={language} />
      <AboutSection />
      {/* <Hero /> */}
      {/* <Services />
      <AboutSection />
      <SponserSection />
      <ServiceSection />
      <GetInTouch />
      <TeamSection teams={teams} />
      <WorkprocessSection />
      <SubscriptionSection />
      <OurGallerySection />
      <NewsSection blogs={blogs} /> */}
      <FooterHomeOne refe={ref2} clicke={handleClick} language={language} />
      <BacktoTopCom />
    </>
  );
}

export default HomeTwo;

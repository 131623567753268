import { createSlice } from "@reduxjs/toolkit";
import en from "../language/en";

const initialState = {
  language: {},
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      switch (action.payload) {
        case "en":
          return {
            ...state,
            language: en,
          };
        case "tr":
          return {
            ...state,
            language: en,
          };
        default:
          return {
            ...state,
            language: en,
          };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
